.Top {
  overflow: hidden;
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: -1;
  opacity: 1;

  background-image_ : url('https://www.indexify.se/images/header02.jpg') ;
	background-size: cover; 
  font-size: 160px;
}

.TopWrapper_ {
  padding-top:90px;
  z-index: 50;
  background-color_: #777;
  opacity: 0.70;
  padding-bottom: 10px;
  height: 200px;  
}

.TopLine {
  color: #fff;
  margin-bottom: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25%;
  z-index: 20;
}

@media all and  (max-width: 991px) {
  .Top {

    font-size: 70px
  }  
}